.about {
    width: 70%;
    margin-left: 20rem;
    margin-right: 7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.heading {
    width: 30%;
    font-size: 1.15rem;
}

._img {
    margin: 10em 10em 15em 10em;
    width: 30%;
    height: 20em;
}

._content_wrapper {
    width: 60%;
}

.contact-btn {
    margin-right: 20px;
    text-decoration: none;
    color: #464646;
}

.contact-btn:hover {
    margin-right: 20px;
    text-decoration: none;
    background-color: #6878a9;
}

.aboutme {
    font-size: 1.2rem;
}

@media (max-width: 1024px) {
    .about {
        margin-left: 3rem;
        margin-right: 3rem;
    }

    ._img {
        margin: 2em;
    }

    ._content_wrapper {
        width: 40%;
    }
}

@media (max-width: 768px) {
    .about {
        margin-left: 4em;
        margin-right: 4em;
        flex-direction: column;
        margin-bottom: 5em;

        & p {
            font-size: 1em;
        }
    }

    ._img {
        width: 15em;
        height: 12em;
    }

    ._content_wrapper {
        width: 100%;
    }
}