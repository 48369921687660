.project {
    width: 80%;
    margin-left: 7rem;
    margin-right: 7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.project-reverse {
    width: 80%;
    margin-left: 7rem;
    margin-right: 7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.__img_wrapper {
    width: 60em;
}

.__img_wrapper > img {
    width: 100%;
}

.__content_wrapper {
    width: 60%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: -0.5rem;
}

.description {
    font-size: 1.2rem;
    width: 80%;
}

.technology {
    font-size: 1rem;
    font-style: italic;
}

.links {
    & a:hover {
        color: #6878a9;
    }

}

@media (max-width: 1024px) {
    .project {
        margin-left: 3rem;
        margin-right: 3rem;
    }

    .__content_wrapper {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .project {
        margin-left: 4em;
        margin-right: 4em;
        flex-direction: column;

        & p {
            font-size: 1em;
        }
    }

    .__img_wrapper {
        width: 340px;
    }

    .__content_wrapper {
        width: 100%;
    }
}