.stormy-night {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: -5;
    transition-property: opacity;
    transition-duration: 3s;
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
}

.stormy-night--entering,
.stormy-night--entered {
    opacity: 1;
}